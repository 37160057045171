<template>
    <select
        class="form-select custom-select appearance-none block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid rounded-xl transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
        :class="{
            'border-box-stroke': !error,
            'border-red-600': error,
        }"
        :placeholder="getPlaceHolder"
        v-model="getValue"
        :title="getSelectedLabel"
        v-if="windowWidth < 500"
        :disabled="disabled || formAlreadySubmitted"
    >
        <option class="option opacity-50" selected hidden value=""> {{ getDefaultSelectText.length > 25 ? getDefaultSelectText.substring(0,23)+'...' : getDefaultSelectText }} </option>
        <option :value="option.option_value" v-for="option,idx in getOptions" :key="idx">
            {{ option.option_label.length > 25 ? option.option_label.substring(0, 23)+'...' : option.option_label }}
        </option>
    </select>

<!-- <select 
        class="form-select w-100 h-100 appearance-none  mt-1 px-3 py-1.5 block text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid rounded-xl transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
        :class="{
            'border-box-stroke': !error,
            'border-red-600': error,
        }"
        :placeholder="getPlaceHolder"
        v-model="getValue"
        :title="getSelectedLabel"
        v-else-if="windowWidth > 499 && windowWidth < 650"
        :disabled="disabled"
    >
            <option selected hidden value=""  class="relative-block  mt-3 px-5 py-5 h-100"> {{ getDefaultSelectText }} </option>
        <option :value="option.option_value" v-for="option,idx in getOptions" :key="idx"       class="relative-block mt-3 px-5 py-5 h-100" >
            {{ option.option_label.length > 45 ? option.option_label.substring(0, 42)+'...' : option.option_label }}
        </option>
    </select> -->

   
    <select class="form-select appearance-none block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid rounded-xl transition ease-in-out m-0 mt-2 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" 
  :class="{
    'border-box-stroke': !error,
    'border-red-600': error,
  }"
  :placeholder="getPlaceholder"
  v-model="getValue"
  :title="getSelectedLabel"
  v-else-if="windowWidth > 499 && windowWidth < 650"
  :disabled="disbaled || formAlreadySubmitted">
  <option class="opacity-50" selected hidden value="">{{ getDefaultSelectText }}</option>
  <option :value="option.option_value" v-for="(option, idx) in getOptions" :key="idx"> 
    {{ option.option_label }}
  </option>
</select>

       

    <select
        class="form-select custom-select appearance-none block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid rounded-xl transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
        :class="{
            'border-box-stroke': !error,
            'border-red-600': error,
        }"
        :placeholder="getPlaceHolder"
        v-model="getValue"
        :title="getSelectedLabel"
        v-else
        :disabled="disabled || formAlreadySubmitted"
    >
        <option class="opacity-50" selected hidden value=""> {{ getDefaultSelectText }} </option>
        <option :value="option.option_value" v-for="option,idx in getOptions" :key="idx" :title="option.option_label">
            {{ option.option_label.length > 100 ? option.option_label.substring(0, 100)+'...' : option.option_label }}
        </option>
    </select>

</template>

<script>
export default {
    data() {
        return {
        windowWidth: 0,
        windowHeight: 0
        };
    },
    mounted() {
        window.addEventListener('resize', this.handleWindowResize);
        this.getWindowSize();
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    },
    props: {
        fieldData: {
        type: Object,
        require: true
        },
        error: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        formAlreadySubmitted: {
            type: Boolean
        }
    },
    created() {
        if (!this.fieldData.value && this.defaultOption) {
            this.getValue = this.defaultOption.option_value;
        }
    },
    computed: {
        getOptions() {
            return this.fieldData.options || [];
        },
        getPlaceHolder() {
            return this.fieldData.placeholder || ''
        },
        defaultOption() {
            return this.getOptions.find(opt => opt.is_default);
        },
        getValue: {
            get() {
                return this.fieldData.value || ''
            },
            set(newValue) {
                // let data = { value: newValue, field_id: this.fieldData.field_id };
                // if (this.fieldData.multi_id) {
                //     data.multi_id = this.fieldData.multi_id;
                //     delete data.field_id
                // }
                // EventBus.$emit("changeFieldValue", data);
                this.$emit('input', newValue);
                this.$emit("handleFieldValueChnage", newValue);
            }
        },
        getSelectedLabel() {
            return this.fieldData.value && this.fieldData.options?.find((el) => el.option_value == this.fieldData.value)?.option_label ? this.fieldData.options?.find((el) => el.option_value == this.fieldData.value)?.option_label : this.getDefaultSelectText
        },
        getDefaultSelectText() {
            // return this.fieldData.label.includes('select') ? this.fieldData.label : "Select"+" "+this.fieldData.label
            return this.fieldData.placeholder || ''
        }
    },
    methods: {
        handleWindowResize() {
            this.getWindowSize();
        },
        getWindowSize() {
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerHeight;
        }
  }
}
</script>

<style lang="scss" scoped>
.form-select {
    -moz-padding-start: calc(.75rem - 3px);

    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-position: right .75rem center;
    background-size: 16px 12px;
     &:focus {
            box-shadow: 0 0 0 1px rgba(37, 99, 235, var(--tw-border-opacity)) !important;
        }
}


select{
    width:100% !important;
    height: 44px;

}

option{
    width: 100% !important;
    height:100px !important;
    position: absolute !important;
    margin-top: 20px;
}

.new-styles .vs__search::placeholder,
.new-styles .vs__dropdown-toggle,
.new-styles .vs__dropdown-menu {
  background: #dfe5fb;
  border: none;
  color: #394066;
  text-transform: lowercase;
  font-variant: small-caps;
}

.new-styles .vs__clear,
.new-styles .vs__open-indicator {
  fill: #394066;
}

@media screen and (max-width:768px){


  select{
    border-radius: 0px !important;
  }


}
</style>
